import React from "react";
import {
  Box,
  Button,
  Stack,
  Text,
  Input,
  Textarea,
  Badge,
} from "@chakra-ui/react";
import { Field as FormikField, Formik } from "formik";
import * as Yup from "yup";
import { Field } from "../ui/field";
import { Fieldset } from "@chakra-ui/react";
import { toaster } from "../ui/toaster";
import { NativeSelectRoot, NativeSelectField } from "../ui/native-select";

const ContactForm = ({ sales = false }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    ...(sales
      ? {}
      : {
          reason: Yup.string().required("Reason for contact is required"),
          message: Yup.string(),
        }),
  });

  const initialValues = {
    name: "",
    email: "",
    ...(sales
      ? {}
      : {
          reason: "",
          message: "",
        }),
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("submitted", values);

    // Simulate a successful submission
    setTimeout(() => {
      toaster.create({
        title: "Success",
        description: "Your message has been sent. We'll be in touch soon!",
        type: "success",
        duration: 5000,
        isClosable: true,
      });
      setSubmitting(false);
      resetForm();
    }, 1000);
  };

  return (
    <Box id="contact-form">
      <Box display="flex" justifyContent="center" mx={{ base: 5, md: 0 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Fieldset.Root size="lg" maxW="md" as="fieldset">
                <Stack align="center">
                  <Fieldset.HelperText
                    fontSize={"md"}
                    textAlign={"center"}
                    color="gray.600"
                  >
                    Please provide your contact details below, and a member of
                    our team will reach out you.
                  </Fieldset.HelperText>
                </Stack>

                <Field label="Name" required color="black">
                  <FormikField name="name">
                    {({ field }) => <Input {...field} type="text" />}
                  </FormikField>
                  {errors.name && touched.name && (
                    <Text color="red.500" fontSize={"sm"}>
                      {errors.name}
                    </Text>
                  )}
                </Field>
                <Field label="Email" required color="black">
                  <FormikField name="email">
                    {({ field }) => <Input {...field} type="email" />}
                  </FormikField>
                  {errors.email && touched.email && (
                    <Text color="red.500" fontSize={"sm"}>
                      {errors.email}
                    </Text>
                  )}
                </Field>
                {!sales && (
                  <>
                    <Field label="Reason for Contact" required color="black">
                      <FormikField name="reason">
                        {({ field }) => (
                          <NativeSelectRoot>
                            <NativeSelectField
                              {...field}
                              placeholder="Select a reason"
                            >
                              <option value="general">General Inquiry</option>
                              <option value="sales">Sales</option>
                              <option value="support">Support</option>
                              <option value="partnership">Partnership</option>
                            </NativeSelectField>
                          </NativeSelectRoot>
                        )}
                      </FormikField>
                      {errors.reason && touched.reason && (
                        <Text color="red.500" fontSize={"sm"}>
                          {errors.reason}
                        </Text>
                      )}
                    </Field>
                    <Field
                      label="Message"
                      optionalText={
                        <Badge size="xs" variant="surface">
                          Optional
                        </Badge>
                      }
                    >
                      <FormikField name="message">
                        {({ field }) => <Textarea {...field} />}
                      </FormikField>
                    </Field>
                  </>
                )}
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText="Submitting"
                  color="white"
                  bg="black"
                  _hover={{
                    bg: "#18181a",
                  }}
                >
                  Submit
                </Button>
              </Fieldset.Root>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ContactForm;
