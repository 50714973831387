import React from "react";
import {
  Box,
  Container,
  VStack,
  Image,
  Stack,
  Link,
  Text,
} from "@chakra-ui/react";
import logo from "../../assets/logos/logo.png";

const Footer = () => {
  return (
    <Box as="footer" bg="gray.100" py={10}>
      <Container maxW="container.xl">
        <VStack spacing={4} align="center">
          <Image src={logo} alt="Company Logo" h="40px" />
          {/* <Stack direction="row" spacing={4}>
            <Link
              href="https://app.coupal.io/privacy"
              color="gray.600"
              isExternal
            >
              Privacy Policy
            </Link>
            <Link
              href="https://app.coupal.io/terms"
              color="gray.600"
              isExternal
            >
              Terms & Conditions
            </Link>
          </Stack> */}
          <Text fontSize="sm" color="gray.500">
            © {new Date().getFullYear()} Coupal. All rights reserved.
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default Footer;
